<template>
    <div class="h-full">
        <PageWithButtonBelow v-if="show_explanation">
            <template v-slot:above-title>
                <TinyNavigation :prev="{}" />
            </template>
            <template v-slot:default>
                <div class="text-white text-center py-20 px-10 max-w-xl mx-auto md:px-6">
                    <h4 class="font-bold text-xl">{{ t('which_game', {coach_name }) }} </h4>
                    <p class="pt-20 mx-auto" style="max-width: 320px">
                        {{ t('choose_game_explanation') }}
                    </p>
                </div>
            </template>

            <template v-slot:button>
                <span @click.prevent="show_explanation = false" class="block w-full rounded-md shadow-sm max-w-xl mx-auto px-4 md:px-6">
                    <button type="button" class="button w-full">
                        {{ t('choose_game') }}
                    </button>
                </span>
            </template>
        </PageWithButtonBelow>
        <div v-else class="px-6 pt-10">
            <TinyNavigation :prev="{}" />
            <h1 class="heading-1 pb-4 whitespace-pre-line" v-text="t('choose_your_game')" />
            <div class="flex flex-wrap">
                <template v-for="match in matches" :key="match.id">
                    <div v-if="!match.completed" class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 px-3 py-3">
                        <MatchItem  :match="match" grayed-out  />
                    </div>

                    <router-link 
                        v-else 
                        :to="{ name: 'review.create', query: {...$route.query, match_id: match.id} }" 
                        class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 px-3 py-3"
                    >
                        <MatchItem :match="match" />
                    </router-link>

                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import MatchItem from "@/components/match/IndexItem";
// import Highlight from "@/components/review/HighlightItem";
import TinyNavigation from "@/components/TinyNavigation";
import { indexMatch } from "@/helpers/api/MatchHelper";
import { showExpert } from "@/helpers/api/ExpertHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ReviewCreateSelectMatch",
    setup() {
        return useI18n({
            viewPrefix: "ReviewCreateSelectMatch"
        });
    },
    components: { PageWithButtonBelow, MatchItem, TinyNavigation },
    created(){
        if(this.$route.query.coach_id){
            showExpert(this.$route.query.coach_id)
                .then((data) => {
                    this.coach_name = data.name;
                });
        }   
        indexMatch()
            .then(({ data }) => {
                this.matches = data;

                if (data.length === 0){
                    this.$router.push({name: 'matches'})
                }
            });
    },
    data(){
        return {
            coach_name: 'de expert',
            show_explanation: true,
            matches: [],
        }
    },
}
</script>
