<template>
    <div class="bg-scorelitgray overflow-hidden shadow rounded-lg group">
        <div class="overflow-hidden w-full h-40 bg-gradient-to-b from-scorelitorange to-scorelitred rounded-t-lg">
            <img 
                v-if="thumbnail" 
                :src="thumbnail" 
                alt="expert avatar"
                class="w-full h-40 object-cover " 
                :class="{
                    'transition duration-300 transform group-hover:-translate-y-1 group-hover:scale-105': !grayedOut,
                    'cursor-not-allowed': grayedOut
                }"
            >
        </div>
        <div 
            class="px-4 py-4 h-30 sm:px-6 "
            :class="{
                'group-hover:bg-gray-800 transition duration-200': !grayedOut,
                'cursor-not-allowed': grayedOut
            }"
        >
            <div class="w-full pl-2 py-2">
                <span class="text-white block font-bold truncate ...">{{ match.video.title }}</span>
                <span v-if="!grayedOut" class="text-xs text-gray-500">{{ longDateToHumanReadable(match.created_at, false) }}</span>
                <span v-else class="text-xs text-red-500" v-text="t('missing')"></span>
            </div>
        </div>
    </div>
</template>

<script>
import useI18n from "@/modules/globalI18n";
import { longDateToHumanReadable } from "@/helpers/DateHelper";

export default {
    setup() {
        return useI18n({
            componentPrefix: "MatchItem"
        });
    },
    props: {
        match: Object,
        grayedOut: Boolean,
    },
    computed:{
        thumbnail: function (){
            return this.match?.video?.thumbnail;
        },
    },
    methods: {
        longDateToHumanReadable,
    },

}
</script>

<style scoped>
    /* credit: https://stackoverflow.com/questions/4861224/how-to-use-css-to-surround-a-number-with-a-circle */
    .numberCircle {
        border-radius: 50%;
        background: linear-gradient(to right, #F7941D 0, #EF4136 100%);
    }
</style>