import axios from 'axios';
import store from '@/store';
import { convertToFormData } from "@/helpers/FormHelper";

// import translation function
import { getGlobalModifiedT } from "@/i18n";
const t = getGlobalModifiedT('helpers.APIMatchHelper.');

const index_match_path = "v1/trainee/match"; // get
const get_match_path = "v1/trainee/match/"; // + id // get
const store_match_path = "v1/trainee/match"; // post
const update_match_path = "v1/trainee/match/"; // + id // patch

function indexMatch()
{
    return new Promise((resolve, reject) => {
        axios.get(index_match_path)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

// rudimentary client side validation
function storeMatchClientSideValidation (data) {
    const errors = {
        title: [],
        description: [],
        age: [],
        file: [],
    }
    let hasErrors = false;
    if (!data.title){
        errors.title = [t('title_required')]
        hasErrors = true;
    }
    if (!data.description){
        errors.description = [t('description_required')]
        hasErrors = true;
    }
    return {hasErrors, errors};
}

function storeMatch(data, onUploadProgress, cancelToken)
{
    const {hasErrors, errors} = storeMatchClientSideValidation(data)
    if(hasErrors){
        return Promise.reject({errors})
    }

    // convert to age
    const { age } = data;
    delete data.age;

    const formData = convertToFormData(data);

    if (age) {
        formData.append('match[age]', age)
    }

    const config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress,
        cancelToken,
    };

    return new Promise((resolve, reject) => {
        axios.post(store_match_path, formData, config)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    reject({errors: {
                        title: [],
                        description: [],
                        file: [],
                    }});
                } else if (err.response && err.response.status === 413) {
                    reject({errors: {
                        title: [],
                        description: [],
                        file: [t("file_too_big_for_server")],
                    }});
                } else if (err.response) {
                    reject(err.response.data);
                } else {
                    reject({errors: {
                        title: [],
                        description: [],
                        file: [err],
                    }});
                }
            });
    });
}

function updateMatch(data, id)
{
    const url = update_match_path + id + '?_method=patch';

    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then((response)=>{
                // save response in store, in case the user jumps back to a previous step.
                store.commit('updateMatch/setMatch', response.data.match);
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function getMatchProperty(key, match_id)
{
    return new Promise((resolve) => {
        getMatch(match_id)
            .then(match => resolve(match[key]))
            .catch(err => console.error(err))
    });
}

function getMatch(match_id, forceRefresh = false)
{
    return new Promise((resolve, reject) => {
        if (!forceRefresh){
            let match = store.getters['updateMatch/matchById'](match_id);
    
            if(match !== undefined){
                return resolve({match});
            }
        }

        const url = get_match_path + match_id

        axios.get(url)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
}

async function cloneMatch(match_id)
{
    const url = `v1/trainee/match/${match_id}/clone`;

    const result = await axios.post(url);
    return result.data;
}

export { indexMatch, storeMatch, updateMatch, getMatchProperty, getMatch, cloneMatch };